import React from "react";
import "../styles/Product.css";

function Product({ name, img, description}) {
    return(
        <div className="product">
            <p className="product-title">{name}</p>
            <img src={img} className="product-img"/>
            <p className="product-description">{description}</p>
        </div>
    );
}

export default Product;