import React from "react";
import Product from "./Product";
import "../styles/Products.css";

import PatinHidraulico from "../img/patin-hidraulico.png";
import PatinHidraulicoDeTijera from "../img/patin-hidraulico-de-tijera.jpg";
import MesaHidraulica from "../img/mesa-hidraulica-de-trabajo.jpg";
import ApiladorSemiElectrico from "../img/apilador-semi-electrico.png";
import ApiladorHidraulicoManual from "../img/apilador-hidraulico-manual.png";

function Products() {
    const prods = [
        {
            name: "Patín hidraúlico",
            img: PatinHidraulico,
            description: "3000 Kg"
        },
        {
            name: "Patín hidraúlico de tijera",
            img: PatinHidraulicoDeTijera,
            description: "1000 Kg"
        },
        {
            name: "Mesa hidraúlica",
            img: MesaHidraulica,
            description: "1000 Kg"
        },
        {
            name: "Apilador semi eléctrico",
            img: ApiladorSemiElectrico,
            description: "1000 Kg"
        },
        {
            name: "Apilador hidraúlico manual",
            img: ApiladorHidraulicoManual,
            description: "1000 Kg"
        }
    ];

    const products = [];
    for (let i = 0; i < prods.length; i++) {
        products.push(
            <Product
                name={prods[i].name}
                img={prods[i].img}
                description={prods[i].description}
            />
        );
    }

    return(
        <div className="products">
            {products}
        </div>
    );
}

export default Products;