import { React, useState } from "react";
import "../styles/Contact.css";
import { Alert } from "@mui/material";
import { ColorRing } from "react-loader-spinner";
import { IoIosCall, IoIosMail, IoMdPerson } from "react-icons/io";
import { MdPlace } from "react-icons/md";
import { CgDetailsMore } from "react-icons/cg";
import { IoClose } from "react-icons/io5";

function Contact({ handleClose }) {

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [formData, setFormData] = useState({
        nombre: '',
        correo: '',
        telefono: '',
        lugar: '',
        detalles: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setShowSuccessAlert(false);
        setShowErrorAlert(false);
        setShowLoading(true);

        fetch('https://simma-backend.onrender.com/order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(data => {
            if (data.ok) {
                console.log("OK");
                setShowLoading(false);
                setShowSuccessAlert(true);
                /*setTimeout(() => {
                    handleClose();
                }, 3000);*/
            } else {
                setShowLoading(false);
                setShowErrorAlert(true);
                console.log(data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setShowLoading(false);
            setShowErrorAlert(true);
        });
    };

    return (
        <div className="contact">
            <div className="contact-form">
                <form onSubmit={handleSubmit}  className='form'>
                    <button onClick={handleClose} className="btn-close"><IoClose className="close-icon"/></button>
                    <div className="form-title">
                        <h3>Estamos listos para trabajar con usted</h3>
                        <p>Ingrese sus datos y le contactaremos</p>
                        <br />
                    </div>
                    <div className="input-group">
                        <input
                            className={formData.nombre.trim() === '' ? 'input' : 'non-empty-input'}
                            name="nombre"
                            type="text"
                            onChange={handleInputChange}
                            value={formData.nombre}
                            required
                        />
                        <label for="nombre" className={formData.nombre.trim() === '' ? '' : 'non-empty'}>
                            <div className="icon-box">
                                <IoMdPerson />
                            </div>
                            Nombre y/o empresa<span className="required">*</span>
                        </label>
                    </div>
                    <div className="input-group">
                        <input
                            className={formData.correo.trim() === '' ? 'input' : 'non-empty-input'}
                            name="correo"
                            type="mail"
                            onChange={handleInputChange}
                            value={formData.correo}
                            required
                        />
                        <label for="correo" className={formData.correo.trim() === '' ? '' : 'non-empty'}>
                            <div className="icon-box">
                                <IoIosMail />
                            </div>
                            Correo<span className="required">*</span>
                        </label>
                    </div>
                    <div className="input-group">
                        <input
                            className={formData.telefono.trim() === '' ? 'input' : 'non-empty-input'}
                            name="telefono"
                            type="tel"
                            onChange={handleInputChange}
                            value={formData.telefono}
                        />
                        <label for="telefono" className={formData.telefono.trim() === '' ? '' : 'non-empty'}>
                            <div className="icon-box">
                                <IoIosCall />
                            </div>
                            Teléfono
                        </label>
                    </div>
                    <div className="input-group">
                        <input
                            className={formData.lugar.trim() === '' ? 'input' : 'non-empty-input'}
                            name="lugar"
                            type="text"
                            onChange={handleInputChange}
                            value={formData.lugar}
                            required
                        />
                        <label for="lugar" className={formData.lugar.trim() === '' ? '' : 'non-empty'}>
                            <div className="icon-box">
                                <MdPlace />
                            </div>
                            Estado y municipio<span className="required">*</span>
                        </label>
                    </div>
                    <div className="input-group">
                        <textarea
                            className={formData.detalles.trim() === '' ? 'input' : 'non-empty-input'}
                            type="text"
                            name="detalles"
                            required
                            value={formData.detalles}
                            onChange={handleInputChange}
                        />
                        <label for="detalles" className={formData.detalles.trim() === '' ? '' : 'non-empty'}>
                            <div className="icon-box">
                                <CgDetailsMore />
                            </div>
                            Detalles de su solicitud<span className="required">*</span>
                        </label>
                    </div>
                    {showErrorAlert && <Alert severity="error" className="alert">Ocurrió un error al registrar su solicitud, por favor intente de nuevo o envíe un correo a ventas@simmasolutions.com</Alert>}
                    {showSuccessAlert && <Alert severity="success" className="alert"><p className="alert-text">Solicitud recibida exitosamente, en un momento recibirá confirmación por correo.</p></Alert>}
                    <button className="btn-enviar">
                        {showLoading ?
                            <div className="loader">
                                <ColorRing
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#ffffff', '#e9ebea', '#ffffff', '#e9ebea', '#ffffff']}
                                />
                            </div>
                            :
                            'Enviar'
                        }
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Contact;