import React from "react";
import "../styles/Link.css";

function Link({ text, to, active = false, animation = null, delay = null, offset = null, shadow = false }) {
    return(
        <a
            href={to}
            className={active ?
                shadow ? "link active btn-shadow" : "link active"
                : shadow ? "link btn-shadow" : "link"
            }
            data-aos={animation}
            data-aos-duration={delay}
            data-aos-offset={offset}
        >{text}</a>
    );
}

export default Link;