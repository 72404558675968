import React from "react";
import "../styles/IconLink.css";

function IconLink({ Icon, text, to, animation = null, delay = null, offset = null }) {
    return(
        <a
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
            data-aos={animation}
            data-aos-duration={delay}
            data-aos-offset={offset}
        ><Icon className="icon-link-icon"/>{text}</a>
    );
}

export default IconLink;