import React from "react";
import "../styles/IconButton.css";

function IconButton({ Icon, to, animation = null, delay = null, offset = null }) {
    return(
        <a
            href={to}
            className="icon-button"
            target="_blank"
            rel="noopener noreferrer"
            data-aos={animation}
            data-aos-duration={delay}
            data-aos-offset={offset}
        ><Icon className=""/></a>
    );
}

export default IconButton;