import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "../styles/SomeServices.css";
import Link from "./Link";
import { isMobile } from 'react-device-detect';

import MantenimientoPatines from "../img/mantenimiento-patines.jpg";
import MantenimientoMontacargas from "../img/mantenimiento-montacargas.jpg";
import MantenimientoCompactadoras from "../img/mantenimiento-prensa.jpg";
import MantenimientoRampas from "../img/mantenimiento-rampas-andenes.png";
import CreacionTableros from "../img/creacion-tablero-industrial.jpeg"
import ServicePreview from "./ServicePreview";


function SomeServices(){
    
    const servs = [
        {
            name: "Mantenimiento a patín hidraúlico",
            img: MantenimientoPatines
        },
        {
            name: "Mantenimiento a montacargas",
            img: MantenimientoMontacargas
        },
        {
            name: "Mantenimiento de compactadoras",
            img: MantenimientoCompactadoras
        },
        {
            name: "Mantenimiento de rampas",
            img: MantenimientoRampas
        },
        {
            name: "Fabricación de tableros de control industriales",
            img: CreacionTableros
        }
    ];

    const services = [];
    for (let i = 0; i < servs.length; i++) {
        services.push(
            <SwiperSlide>
                <ServicePreview
                    name={servs[i].name}
                    img={servs[i].img}
                    delay={(i + 1) * 1000}
                />
            </SwiperSlide>
        );
    }

    return(
        <div>
            <div className="some-services">
                <h1 data-aos="fade-up" data-aos-duration={2000}>Servicios más solicitados</h1>
                <Swiper
                spaceBetween={0}
                slidesPerView={isMobile ? 2 : 4}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
                    {services}
                </Swiper>
                <Link
                    text="Ver todos los servicios"
                    to="/servicios"
                    active={false}
                    shadow={true}
                    animation="fade-up"
                    delay={2000}
                    offset={1}/>
            </div>
        </div>
    );
}

export default SomeServices;