import React from "react";
import ProductPreview from "./ProductPreview";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "../styles/SomeProducts.css";
import Link from "./Link";
import { isMobile } from 'react-device-detect';

import PatinHidraulico from "../img/patin-hidraulico.png";
import PatinHidraulicoDeTijera from "../img/patin-hidraulico-de-tijera.jpg";
import MesaHidraulica from "../img/mesa-hidraulica-de-trabajo.jpg";
import ApiladorHidraulicoManual from "../img/apilador-hidraulico-manual.png";


function SomeProducts(){
    
    const prods = [
        {
            name: "Patín hidraúlico",
            img: PatinHidraulico
        },
        {
            name: "Patín hidraúlico de tijera",
            img: PatinHidraulicoDeTijera
        },
        {
            name: "Mesa hidraúlica",
            img: MesaHidraulica
        },
        {
            name: "Apilador hidraúlico manual",
            img: ApiladorHidraulicoManual
        }
    ];

    const products = [];
    for (let i = 0; i < prods.length; i++) {
        products.push(
            <SwiperSlide>
                <ProductPreview
                    name={prods[i].name}
                    img={prods[i].img}
                    delay={(i + 1) * 1000}
                />
            </SwiperSlide>
        );
    }

    return(
        <div>
            <div className="some-products">
                <h1 data-aos="fade-up" data-aos-duration={2000}>Productos más vendidos</h1>
                <Swiper
                className="products-swiper"
                spaceBetween={0}
                slidesPerView={isMobile ? 2 : 4}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
                    {products}
                </Swiper>
                <Link text="Ver todos los productos"
                    to="/productos"
                    active={false}
                    animation="fade-up"
                    delay={2000}
                    shadow={true}/>
            </div>
        </div>
    );
}

export default SomeProducts;