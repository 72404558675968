import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Order from "./components/Order";
import Products from "./components/Products";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Services from "./components/Services";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "./components/Footer";

function App() {
  AOS.init({ offset: 120 });
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
          <Route path="/productos" element={<Products/>}/>
          <Route path="/servicios" element={<Services/>}/>
      </Routes>
      <Footer />
      <Order/>
    </BrowserRouter>
  );
}

export default App;
