import React, { useState } from "react";
import Modal from '@mui/material/Modal';
import "../styles/Order.css";
import Contact from "./Contact";

function Order() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <button className="btn-order active" onClick={handleOpen}>Solicitar cotización</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal"
            >
                <Contact handleClose={handleClose}/>
            </Modal>
        </>
    );
}

export default Order;