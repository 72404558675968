import React from "react";
import "../styles/ServicePreview.css";

function ServicePreview({ name, img, delay}) {
    return(
        <div className="service-preview" data-aos="fade-up" data-aos-duration={delay}>
            <img src={img} className="service-preview-img"/>
            <p className="preview-title">{name}</p>
        </div>
    );
}

export default ServicePreview;