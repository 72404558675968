import React from "react";
import "../styles/ProductPreview.css";

function ProductPreview({ name, img, delay}) {
    return(
        <div className="product-preview" data-aos="fade-up" data-aos-duration={delay}>
            <img src={img} className="preview-img"/>
            <p className="preview-title">{name}</p>
        </div>
    );
}

export default ProductPreview;