import React from "react";
import "../styles/Services.css";

import MantenimientoPatines from "../img/mantenimiento-patines.jpg";
import MantenimientoMontacargas from "../img/mantenimiento-montacargas.jpg";
import MantenimientoCompactadoras from "../img/mantenimiento-prensa.jpg";
import MantenimientoRampas from "../img/mantenimiento-rampas-andenes.png";
import CreacionTableroIndustrial from "../img/creacion-tablero-industrial.jpeg"
import Product from "./Product";

function Services() {
    /*const servs = [
        {
            name: "Mantenimiento preventivo y correctivo a patines hidraulicos.",
            img: MantenimientoPatines,
            description: "Nuestro servicio de mantenimiento de patines hidráulicos está diseñado para garantizar un rendimiento óptimo, seguridad y durabilidad de tus equipos.",
            list: [
                "Inspección inicial.",
                "Mantenimiento preventivo.",
                "Reparación o sustitucion de componentes.",
                "Pruebas de funcionamiento.",
                "Ajustes de nivelación y calibración.",
                "Capacitación de personal.",
                "Informe detallado."
            ]
        },
        {
            name: "Mantenimiento preventivo y correctivo a montacargas.",
            img: MantenimientoMontacargas,
            description: "Nuestro servicio de mantenimiento de montacargas está diseñado para asegurar el rendimiento óptimo, la seguridad y la durabilidad de tus equipos de manejo de materiales. Nos especializamos en la atención integral de montacargas de diversos tipos y capacidades, proporcionando soluciones personalizadas para satisfacer las necesidades específicas de tu flota.",
            list: [
                "Inspección inicial.",
                "Mantenimiento preventivo.",
                "Reparación o sustitucion de componentes.",
                "Pruebas de funcionamiento.",
                "Ajustes de nivelación y calibración.",
                "Capacitación de personal.",
                "Informe detallado."
            ]
        }
    ];*/

    const servs = [
        {
            name: "Mantenimiento preventivo y correctivo a patines hidraulicos",
            img: MantenimientoPatines,
            description: " "
        },
        {
            name: "Mantenimiento preventivo y correctivo a montacargas",
            img: MantenimientoMontacargas,
            description: " "
        },
        {
            name: "Mantenimiento de compactadoras hidraúlicas",
            img: MantenimientoCompactadoras,
            description: " "
        },
        {
            name: "Mantenimiento de rampas hidraúlicas para andenes",
            img: MantenimientoRampas,
            description: " "
        },
        {
            name: "Fabricación de tableros de control industriales",
            img: CreacionTableroIndustrial,
            description: " "
        }
    ];

    const services = [];
    for (let i = 0; i < servs.length; i++) {
        services.push(
            <Product
                name={servs[i].name}
                img={servs[i].img}
                description={servs[i].description}
                list={servs[i].list}
            />
        );
    }

    return(
        <div className="services">
            {services}
        </div>
    );
}

export default Services;