import Main from "./Main";
import SomeProducts from "./SomeProducts";
import SomeServices from "./SomeServices"

function Home(){
    return(<>
        <Main/>
        <SomeProducts/>
        <SomeServices/>
    </>)
}

export default Home;