import { useState, useEffect }from "react";

function ChangingText({ toRotate, pauseTime }){
    const [index, setIndex] = useState(0);
    const [invisible, setInvisible] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % toRotate.length);
        }, pauseTime);

        return () => clearInterval(intervalId);
    }, [toRotate])

    return(
        <p className={invisible ? "highlighted-text invisible" : "highlighted-text"}>
            <span>{toRotate[index]}</span>
        </p>
    );
}

export default ChangingText;