import { React }from "react";
import "../styles/Main.css";
import ChangingText from "./ChangingText";
import { IoLogoWhatsapp, IoIosCall, IoIosMail } from "react-icons/io";
import IconButton from "./IconButton";

function Main() {
    const textToRotate = [
        "Brindamos recursos de ingeniería y mantenimiento a equipos industriales.",
        "Garantizamos la atención y calidad en cada trabajo.",
        //"Nuestros valores: Excelencia, colaboración, lealtad, responsabilidad y transparencia.",
        "Tenemos más de 25 años de experiencia en el área.",
        "Más de 100 clientes concurrentes nos respaldan."
    ];

    return (
        <div className="main">
            <div className="main-info">
                <p><span className="acronym">S</span>ervicios de <span className="acronym">I</span>ngeniería en <span className="acronym">M</span>aquinaria y <span className="acronym">M</span>ontacargas <span className="acronym">A</span>tlacomulco.</p> 
            </div>
            <div className="contact-buttons-box">
                <div className="">
                    <IconButton
                        Icon={IoLogoWhatsapp}
                        to="https://wa.me/5217122284310?text=%C2%A1Hola!"
                    />
                </div>
                <div className="contact-buttons-item">
                    <IconButton Icon={IoIosCall} to="tel:7122284310"/>
                </div>
                <div className="">
                    <IconButton Icon={IoIosMail} to="mailto:ventas@simmasolutions.com" className="contact-buttons-item"/>
                </div>
            </div>
            <div className="secondary-info">
                <ChangingText toRotate={textToRotate} velocity={100} pauseTime={7000}/>
            </div>
        </div>
    );

    /*
    return (
        <div className="main">
            <div className="secondary-info">
                <ChangingText toRotate={textToRotate} velocity={100} pauseTime={7000}/>
            </div>
            <div className="main-info">
                <p>Servicios de Ingeniería en Maquinaria y Montacargas.</p> 
            </div>
        </div>
    );*/
}

export default Main;