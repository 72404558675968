import React from "react";
import "../styles/Footer.css";
import { IoLogoWhatsapp, IoIosCall, IoIosMail } from "react-icons/io";
import IconLink from "./IconLink";

function Footer() {
    return (
        <div className="footer">
            <div className="footer-box margin-box">
                <h3 className="margin-item">Horario</h3>
                <p>Lunes a viernes 9:00 - 18:00</p>
                <p>Sabado y domingo - Cerrado</p>
            </div>
            <div className="footer-box margin-box">
                <IconLink Icon={IoIosCall} to="tel:5523902737" text="55-2390-2737" className="margin-item"/>
                <IconLink Icon={IoIosCall} to="tel:7121201080" text="712-120-1080" className="margin-item"/>
                <IconLink Icon={IoIosCall} to="tel:7122284310" text="712-228-4310" className="margin-item"/>
            </div>
            <div className="footer-box margin-box">
                <IconLink Icon={IoLogoWhatsapp} to="https://wa.me/5215523902737?text=%C2%A1Hola!" text="552-390-2737"/>
                <IconLink Icon={IoLogoWhatsapp} to="https://wa.me/5217122284310?text=%C2%A1Hola!" text="712-228-4310"/>
            </div>
            <div className="footer-box">
                <IconLink Icon={IoIosMail} to="mailto:ventas@simmasolutions.com" text="ventas@simmasolutions.com"/>
            </div>
        </div>
    );
}

export default Footer;