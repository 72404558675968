import React, { useEffect, useState } from "react";
import logo from "../logo.svg";
import "../styles/Header.css";
import Link from "./Link";

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const logoStyle = {
        fill: 'white',
    };

    return (
        <div className={isScrolled ? "header shadow" : "header"}>
            <div className="logo">
                <img src={logo} className="app-logo" alt="logo" style={logoStyle}/>
            </div>
            <div className="nav">
                <Link text="Inicio" to="/" active={false} />
                <Link text="Productos" to="/productos" active={false} />
                <Link text="Servicios" to="/servicios" active={false} />
            </div>
        </div>
    );
}

export default Header;